import type { MapConfig } from '@indomita-website/search/types';
import type { RedisOptions } from 'ioredis';

import type { AdSlots } from 'src/types/ad-slots';
import type { BannerConfig } from 'src/types/banner';
import type { ENERGY_RATE } from 'src/types/energy-rate';
import type { DiscoverItem } from 'src/types/homepage';
import type { Language } from 'src/types/translations';

//@ts-expect-error
import { config } from '<product-config>';

export type CacheOptions = {
  enabled?: boolean;
  namespace?: string;
} & RedisOptions;

type cityGuideMapZoom = {
  country: number;
  region: number;
  province: number;
  city: number;
  cityZone?: number;
  road?: number;
};

export type ProductConfig = {
  lead: {
    emailRequired: boolean;
    phoneRequired: boolean;
  };
  logoConfig: {
    url: string;
    positiveUrl: string;
    width: number;
    height: number;
    mobile: {
      altURL: string;
      positiveURL: string;
      width: number;
      height: number;
    };
  };
  logoConfigPrintPage: {
    url: string;
    width: number;
    height: number;
  };

  headlineSvg: boolean;
  defaultSearchPrefix: string;
  name: string;
  domainName: string;
  brandName: string;
  metricsDomain: string;
  origin: string;
  countryCode: string;
  country?: string;
  defaultLocale: Language;
  blog?: string;
  external_crm_host?: string;
  agencySignUp?: string;
  allowedLanguages: Record<string, { code: string; label: string }>;
  phoneCountryCode: string;
  mapConfig: MapConfig & {
    markerTilesUrl: string;
    tiles3DRasterUrlTemplate?: string;
    tiles3DVectorUrlTemplate?: string;
    poiCdnVersion: string;
  };
  cityGuide: {
    mapConfig: MapConfig;
    // `geographyInfoMapZoom` is the map zoom level for each geography info
    // represented by the city-guide page: e.g.: Rome or Lazio.
    geographyInfoMapZoom: cityGuideMapZoom;
    // `apiMapZoom` is the map zoom level to which the api returns given
    // entities types (polygons)
    apiMapZoom: cityGuideMapZoom;
  };
  geographySearch: {
    drawEnabled: boolean;
  };
  geocodeIsolinesApiKey: string;
  geocodeReverseApiKey: string;
  geocodeAddressApiKey: string;
  geocodeAddressApiOrigin: string;
  mapResultsLimit: number;
  mapResultMaxLabel: number;
  mapMaxResultsPerPage: number;
  listResultsLimit: number;
  listResultMaxLabel: number;
  listMaxResultsPerPage: number;
  energyRange: {
    [key in keyof typeof ENERGY_RATE]?: (
      | 'A1-A4'
      | 'A+'
      | 'A'
      | 'B'
      | 'C'
      | 'D'
      | 'E'
      | 'F'
      | 'G'
      | 'H'
      | 'I'
    )[];
  };
  externalBaseUrl?: string;
  publishAdsUrl?: string;
  publishedAdsLandingUrl?: string;
  secretPropertiesUrl?: string;
  publishAdsUrlFooter?: string;
  evaluateBuildingUrl?: string;
  sellerLeadUrlOldMenu?: string;
  sellerLeadUrl?: string;
  mortgageUrl?: string;
  mortgagePreApproveUrl?: string;
  cityGuideUrl?: string;
  insightsUrl?: string;
  searchAgenciesUrl?: string;
  myHomeUrl?: string;
  agencyUrl: string;
  agencyUrlPro?: string;
  agencyUrlConstructor?: string;
  associationUrl?: string;
  franchisingUrl?: string;
  agencyAgentsUrl?: string;
  searchAbroadUrl?: string;
  whoWeAreUrl?: string;
  antiScamUrl?: string;
  euroansaUrl?: string;
  contactUsUrl?: string;
  userAssistance?: string;
  internationalLuxuryUrl?: string;
  networkGroupUrl?: string;
  professionalReportUrl?: string;
  academyUrl?: string;
  virtualTourUrl?: string;
  immoVisitaUrl?: string;
  immoSoftwareUrl?: string;
  racSoftwareUrl?: string;
  publishAdUrl?: string;
  workWithUsUrl?: string;
  advertisingUrl?: string;
  pressOfficeUrl?: string;
  leaderUrl?: string;
  newsUrl?: string;
  dossiersUrl?: string;
  magazineUrl?: string;
  houseFinancingUrl?: string;
  user: {
    publishedAdsUrl: string;
    savedAdsUrl: string;
    blacklistedAdsUrl: string;
    lastAdsUrl?: string;
    savedSearchesUrl: string;
    loginUrl: string;
    messagesUrl?: string;
    logoutUrl: string;
    registerUrl: string;
    passwordRecoveryUrl: string;
    appraisalUrl?: string;
    profileUrl?: string;
    editUrl: (realEstateId: number) => string;
    upgradeUrl: (realEstateId: number) => string;
  };
  partitaIva?: string;
  socials?: {
    instagramUrl?: string;
    facebookUrl?: string;
    twitterUrl?: string;
    linkedinUrl?: string;
  };
  terms: {
    conditionUrl: string;
    privacyUrl: string;
    cookiesUrl: string;
  };
  hasFooterLinks: boolean;
  realEstatePath: string;
  facebookAppId: number;
  gtm: {
    id: string;
  };
  mapchartImgServiceVersion: string;
  vhostUrlCdnMaps: string;
  staticMapUrl: string;
  redis?: CacheOptions;
  appStores?: {
    iOS?: string;
    android?: string;
    huawei?: string;
  };
  appConfig?: {
    img?: string;
    iOS?: {
      app_name: string;
    };
    android?: {
      app_host: string;
      uri_scheme: string;
      app_package: string;
    };
    huawei?: {
      app_package: string;
    };
  };
  preconnectUrls?: string[];
  brazeConfig?: {
    apiKey: string;
    baseUrl: string;
  };
  growthbookConfig?: {
    clientKey: string;
    apiHost: string;
  };
  headerUrls: {
    text: string;
    url: string;
    external?: boolean;
    externalUrl?: string;
    isClientSideNavigation?: boolean;
  }[];
  alternateHeaderUrls: {
    text: string;
    url: string;
    external?: boolean;
    externalUrl?: string;
    isClientSideNavigation?: boolean;
  }[];
  discoverUrls: DiscoverItem[];
  alternateDiscoverUrls: DiscoverItem[];
  assets: {
    themecolor?: string;
    maskIcon?: string;
    shortcutIcon?: string;
    appleTouchIcon?: string;
    favicons?: {
      url: string;
      width: number;
      height: number;
      type: string;
      rel: string;
    }[];
  };
  visibilities: {
    supervetrina: {
      label: string;
      style: string;
      isMosaic?: boolean;
    };
    vetrina: {
      label: string;
      style: string;
      isMosaic?: boolean;
    };
    star: {
      label: string;
      style: string;
      isMosaic?: boolean;
    };
    top: {
      label: string;
      style: string;
      isMosaic?: boolean;
    };
    premium: {
      label: string;
      style: string;
      isMosaic?: boolean;
    };
  };
  mainPartnerHome?: {
    img: {
      src: string;
      src_2x?: string;
      width?: number;
      height?: number;
      alt: string;
    };
    url?: string;
    target?: string;
    rel?: string;
  };
  agenciesExpertResultsLimit: number;
  spuerkeess?: {
    apiUrl: string;
    sdkUrl: string;
    fallbackLang: string;
    sdkAvailableLang: string[]; // TODO: refactoring
  };
  laluxAssurance?: {
    sdkUrl: string;
    fallbackLang: string;
    sdkAvailableLang: string[];
    sdkHouseType: {
      [key: string]: number;
    };
    sdkContractType: {
      [key: string]: number;
    };
  };
  detailBannerConfig?: {
    id: string;
    name: string;
    width: string;
    height: string;
    display: 1 | 0;
    isActive: '1' | '0';
  };
  provincePathSuffix: 'provincia' | 'perifereia' | 'zone';
  homepageHeadline: {
    default: {
      transKey: string;
      url?: string;
      verticals?: {
        [key: string]: {
          transKey: string;
          url?: string;
        };
      };
    };
    alternate?: {
      transKey: string;
      url?: string;
      verticals?: {
        [key: string]: {
          transKey: string;
          url?: string;
        };
      };
    };
  };
  homepageExandibleCategories: string[];
  didomiNoticeID?: string;
  oAuth?: {
    google: {
      clientId: string;
    };
  };
  timeZone: string;
  agencyBanners?: BannerConfig;
  agenciesWithBaseHeaderColor: number[];
  euroAnsa?: {
    seoTitle: string;
    seoDescription: string;
    seoKeywords: string;
  };
  segmentConfig: {
    apiHost: string;
    apiKey: string;
  };
  trackingReferrerRoutes?: Record<string, string>;
  adSlots?: AdSlots;
};

interface GetConfig {
  <K extends keyof ProductConfig>(key: K): ProductConfig[K];
}

export const getProductConfig: GetConfig = <K extends keyof ProductConfig>(
  key: K
) => {
  return config[key];
};
